import React, { useState } from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const validDbValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

const handleDonationClick = () => {
  const paypalURL = 'https://paypal.me/AlbertoPurpura';
  window.location.href = paypalURL;
};


const Alert = ({ message, onClose }) => (
  <div className="alert">
    <div className="alert-content">
      <span className="alert-message">{message}</span>
      <button className="alert-close" onClick={onClose}>
        &times;
      </button>
    </div>
  </div>
);

const App = () => {
  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  // }, []);

  // const handleButtonClick = () => {
  //   ReactGA.event({
  //     category: 'User',
  //     action: 'Clicked a button'
  //   });
  // };

  const [chartData, setChartData] = useState({
    labels: [500, 1000, 2000, 4000],
    datasets: [

      {
        label: 'Orecchio Destro',
        data: [],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
      {
        label: 'Orecchio Sinistro',
        data: [],
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        pointStyle: 'CrossRot',
      },
    ],
  });
  const vocData = [
    { db: 0, voc: 20 },
    { db: 5, voc: 10 },
    { db: 10, voc: 8 },
    { db: 15, voc: 7 },
    { db: 20, voc: 6 },
    { db: 25, voc: 5 },
    { db: 30, voc: 4 },
    { db: 35, voc: 3 },
    { db: 40, voc: 2.5 },
    { db: 45, voc: 2 },
    { db: 50, voc: 1.5 },
    { db: 55, voc: 1 },
    { db: 60, voc: 0.5 },
    { db: 65, voc: 0.375 },
    { db: 70, voc: 0.25 },
    { db: 75, voc: 0 },
    { db: 80, voc: 0 },
    { db: 85, voc: 0 },
    { db: 90, voc: 0 },
    { db: 95, voc: 0 },
    { db: 100, voc: 0 },
  ];

  const tableData = [
    { db: 0, db500: 0, db1000: 0, db2000: 0, db4000: 0 },
    { db: 5, db500: 1.25, db1000: 1.5, db2000: 2, db4000: 0.25 },
    { db: 10, db500: 2.5, db1000: 3, db2000: 4, db4000: 0.5 },
    { db: 15, db500: 3.75, db1000: 4.5, db2000: 6, db4000: 0.75 },
    { db: 20, db500: 5, db1000: 6, db2000: 8, db4000: 1 },
    { db: 25, db500: 6.25, db1000: 7.5, db2000: 10, db4000: 1.25 },
    { db: 30, db500: 7.5, db1000: 9, db2000: 12, db4000: 1.5 },
    { db: 35, db500: 8.75, db1000: 10.5, db2000: 14, db4000: 1.75 },
    { db: 40, db500: 10, db1000: 12, db2000: 16, db4000: 2 },
    { db: 45, db500: 11.25, db1000: 13.5, db2000: 18, db4000: 2.25 },
    { db: 50, db500: 12.5, db1000: 15, db2000: 20, db4000: 2.5 },
    { db: 55, db500: 13.75, db1000: 16.5, db2000: 22, db4000: 2.75 },
    { db: 60, db500: 15, db1000: 18, db2000: 24, db4000: 3 },
    { db: 65, db500: 16.25, db1000: 19.5, db2000: 26, db4000: 3.25 },
    { db: 70, db500: 17.5, db1000: 21, db2000: 28, db4000: 3.5 },
    { db: 75, db500: 18.75, db1000: 22.5, db2000: 30, db4000: 3.75 },
    { db: 80, db500: 20, db1000: 24, db2000: 32, db4000: 4 },
    { db: 85, db500: 21.25, db1000: 25.5, db2000: 34, db4000: 4.25 },
    { db: 90, db500: 22.5, db1000: 27, db2000: 36, db4000: 4.5 },
    { db: 95, db500: 23.75, db1000: 28.5, db2000: 38, db4000: 4.75 },
    { db: 100, db500: 25, db1000: 30, db2000: 40, db4000: 5 },
  ];

  const [rightEar, setRightEar] = useState({
    500: 0,
    1000: 0,
    2000: 0,
    4000: 0,
  });
  const [leftEar, setLeftEar] = useState({
    500: 0,
    1000: 0,
    2000: 0,
    4000: 0,
  });

  const [outputs, setOutputs] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showReferenceValues, setShowReferenceValues] = useState(false);
  const [leftEarVOC, setLeftEarVOC] = useState(null);
  const [rightEarVOC, setRightEarVOC] = useState(null);

  const toggleReferenceValues = () => {
    setShowReferenceValues(!showReferenceValues);
  };


  const handleChange = (ear, frequency, value) => {
    if (!validDbValues.includes(parseInt(value))) {
      setShowAlert(true);
      setAlertMessage('Please select a valid dB value from the dropdown.');
      return;
    }

    if (ear === 'right') {
      setRightEar({ ...rightEar, [frequency]: value });
    } else {
      setLeftEar({ ...leftEar, [frequency]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newOutputs = [];

    const leftEarData = [
      leftEar[500],
      leftEar[1000],
      leftEar[2000],
      leftEar[4000],
    ];

    const rightEarData = [
      rightEar[500],
      rightEar[1000],
      rightEar[2000],
      rightEar[4000],
    ];

    for (const frequency of [500, 1000, 2000, 4000]) {
      const rightEarValue = computeOutput(rightEar[frequency], frequency);
      const leftEarValue = computeOutput(leftEar[frequency], frequency);

      newOutputs.push({ value: rightEarValue, frequency });
      newOutputs.push({ value: leftEarValue, frequency });
    }

    setOutputs(newOutputs);

    const { leftEarVOC: computedLeftEarVOC, rightEarVOC: computedRightEarVOC } = computeVOC(newOutputs);
    setLeftEarVOC(computedLeftEarVOC);
    setRightEarVOC(computedRightEarVOC);

    setChartData({
      labels: [500, 1000, 2000, 4000],
      datasets: [
        {
          label: 'Orecchio Destro',
          data: rightEarData,
          pointRadius: 8,
          pointHoverRadius: 15,
          pointStyle: 'circle',
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',

        },
        {
          label: 'Orecchio Sinistro',
          data: leftEarData,
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          pointStyle: 'crossRot',
          pointRadius: 8,
          pointHoverRadius: 15
        },
      ],
    });
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Frequenza in Hertz (Hz)',
        },
        type: 'logarithmic',
        min: 400,
        max: 5000,
        ticks: {
          callback: function (value, index, values) {
            if (value === 500) return value;
            if (value === 1000) return value;
            if (value === 2000) return value;
            if (value === 4000) return value;
            return '';
          },
        }

      },
      y: {
        title: {
          display: true,
          text: 'Soglia uditiva in Decibel (dB)',
        },
        max: 120,
        min: -10,
        reverse: true,
        ticks: {
          stepSize: 5,
          display: true,
          includeBounds: true,
          // autoSkip: false,
          // values: [-10, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120], // All tick values with step 5
          // callback: function (value, index, values) {
          //   if (value === 0) return 'Udito normale';
          //   if (value === 20) return 'Ipoacusia lieve';
          //   if (value === 40) return 'Ipoacusia moderata';
          //   if (value === 70) return 'Ipoacusia grave';
          //   if (value === 95) return 'Ipoacusia profonda';
          //   return '';
          // },

        },
      },
    },
  };


  const computeOutput = (dbValue, frequency) => {
    const row = tableData.find((row) => row.db === parseInt(dbValue));
    if (row) {
      return row[`db${frequency}`];
    }
    return '-';
  };


  const computeVOC = (outputs) => {
    const leftEarOutputs = outputs.filter((_, index) => index % 2 !== 0);
    const rightEarOutputs = outputs.filter((_, index) => index % 2 === 0);

    const leftEarVOC = computeVOCForEar(leftEarOutputs);
    const rightEarVOC = computeVOCForEar(rightEarOutputs);

    return { leftEarVOC, rightEarVOC };
  };

  const computeVOCForEar = (earOutputs) => {
    const sum = earOutputs.reduce((acc, curr) => acc + curr.value, 0);
    const closestPoints = findClosestPoints(sum, vocData);
    // console.log(closestPoints, sum);
    if (closestPoints.length === 1) {
      return null;
    }

    const [lower, upper] = closestPoints;
    const lowerDb = lower.db;
    const upperDb = upper.db;
    const lowerVoc = lower.voc;
    const upperVoc = upper.voc;

    const lerp = (x, y, a) => x * (1 - a) + y * a;
    const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
    const invlerp = (x, y, a) => clamp((a - x) / (y - x));

    const interpolatedVoc = lerp(lowerVoc, upperVoc, invlerp(lowerDb, upperDb, sum))
    return interpolatedVoc;
  };

  const findClosestPoints = (sum, data) => {
    const sortedData = [...data].sort((a, b) => a.db - b.db);
    const closestPoints = [];

    let smallerPoint = null;
    let largerPoint = null;

    for (let i = 0; i < sortedData.length; i++) {
      const point = sortedData[i];

      if (point.db < sum) {
        smallerPoint = point;
      } else if (point.db > sum) {
        largerPoint = point;
        break;
      }
    }

    if (smallerPoint !== null) {
      closestPoints.push(smallerPoint);
    }

    if (largerPoint !== null) {
      closestPoints.push(largerPoint);
    }
    return closestPoints;
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  return (
    <>
      <div className='body'>
        {showAlert && <Alert message={alertMessage} onClose={handleAlertClose} />}

        <h1>Convertitore soglie uditive - VOC (m)</h1>
        <p className="page-description">
          Questa web app nasce come strumento per i medici del lavoro ed è utile per la rappresentazione grafica delle soglie uditive alle frequenze di interesse per la percezione della voce parlata e per il calcolo della distanza massima di percezione della stessa.
          In particolare, nasce dall'esigenza di calcolare tali soglie al fine della formulazione dell’idoneità lavorativa degli autisti.

          Si rimandano i limiti decisionali alla valutazione del medico competente di riferimento.
          I valori di riferimento utilizzati per la conversione di ciascuna soglia sono indicati a fondo pagina.
        </p>

        <form onSubmit={handleSubmit}>
          <div style={{ display: 'flex' }}>

            <div className='subform'>
              <h3>Orecchio DX</h3>
              <label>
                500 Hz:&nbsp;&nbsp;&nbsp;
                <select
                  value={rightEar[500]}
                  onChange={(e) => handleChange('right', 500, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                1000 Hz:&nbsp;
                <select
                  value={rightEar[1000]}
                  onChange={(e) => handleChange('right', 1000, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                2000 Hz:&nbsp;
                <select
                  value={rightEar[2000]}
                  onChange={(e) => handleChange('right', 2000, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                4000 Hz:&nbsp;
                <select
                  value={rightEar[4000]}
                  onChange={(e) => handleChange('right', 4000, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className='subform'>
              <h3>Orecchio SX</h3>
              <label>
                500 Hz:&nbsp;&nbsp;&nbsp;
                <select
                  value={leftEar[500]}
                  onChange={(e) => handleChange('left', 500, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                1000 Hz:&nbsp;
                <select
                  value={leftEar[1000]}
                  onChange={(e) => handleChange('left', 1000, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                2000 Hz:&nbsp;
                <select
                  value={leftEar[2000]}
                  onChange={(e) => handleChange('left', 2000, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                4000 Hz:&nbsp;
                <select
                  value={leftEar[4000]}
                  onChange={(e) => handleChange('left', 4000, e.target.value)}
                >
                  {validDbValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>



          <button type="submit">Calcola</button>
        </form>

        <div className="outputs">
          <h3>Soglie audiometriche a diverse frequenze:</h3>
          <table>
            <thead>
              <tr>
                <th>Frequenza</th>
                <th>Orecchio DX</th>
                <th>Orecchio SX</th>
              </tr>
            </thead>
            <tbody>
              {[500, 1000, 2000, 4000].map((frequency) => (
                <tr key={frequency}>
                  <td>{frequency} Hz</td>
                  <td>{outputs.find((output) => output.frequency === frequency && outputs.indexOf(output) % 2 === 0)?.value || '-'}</td>
                  <td>{outputs.find((output) => output.frequency === frequency && outputs.indexOf(output) % 2 !== 0)?.value || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='voc-outputs'>
            <h3>VOC (m):</h3>
            <p><span style={{ fontWeight: 'bold' }}>Orecchio DX: </span>{rightEarVOC !== null ? rightEarVOC.toFixed(2) : '-'}</p>
            <p><span style={{ fontWeight: 'bold' }}>Orecchio SX: </span> {leftEarVOC !== null ? leftEarVOC.toFixed(2) : '-'}</p>

          </div>
          <div className="chart-container">
            <h3>Soglie Uditive</h3>
            <Line data={chartData} options={chartOptions} />
          </div>
        </div>
        <div className='ref-table'>
          <button
            className="ref-table-header d-flex align-items-center justify-content-between"
            onClick={toggleReferenceValues}
            aria-controls="reference-values-collapse"
            aria-expanded={showReferenceValues}
          >
            <h3>
              Valori di riferimento&nbsp;
              <span className="toggle-text">
                {showReferenceValues ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
              </span>
            </h3>
          </button>
          {showReferenceValues && (
            <>
              <div className="ref-tables">
                <div>
                  <p>Tabella dei valori percentuali di perdita uditiva alle varie soglie audiometriche sulle diverse frequenze:</p>
                  <table>
                    <thead>
                      <tr>
                        <th>Db</th>
                        <th>500 Hz</th>
                        <th>1000 Hz</th>
                        <th>2000 Hz</th>
                        <th>4000 Hz</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.db}</td>
                          <td>{row.db500}</td>
                          <td>{row.db1000}</td>
                          <td>{row.db2000}</td>
                          <td>{row.db4000}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div>
                  <p>Tabella dei valori VOC (m):</p>
                  <table>
                    <thead>
                      <tr>
                        <th>Db</th>
                        <th>VOC (m)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vocData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.db}</td>
                          <td>{row.voc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div>
                  <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Fonte: </p>
                  <p>Tabelle di Bocca-Pellegrini.</p>
                </div>
              </div>

            </>
          )}

        </div>





      </div>

      <footer className="footer">
        <div className="donation-footer-container">
          <p>Se trovi utile questa applicazione, considera di offrirmi una birra virtuale!</p>
          <button className="donation-button" onClick={handleDonationClick}>
            Offrimi una birra 🍺
          </button>
          
        </div>
      </footer>
    </>
  );
};

export default App;